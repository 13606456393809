import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { ApiService } from "../api/services";
import { UpdateMessageService } from "./update-message.service";

export interface FileUpload {
	/**
	 * Local file, with original name
	 */
	file: File;
	/**
	 * File name used during upload (can be different from local file name)
	 */
	fileName: string;
	progress: number;
	completed: boolean;

	/**
	 * If an error occurred, or the upload was cancelled, this will be set. Otherwise null.
	 */
	error: string | null;
}

export interface FileUploadStartedEvent {
	upload: FileUpload;
	upload$: Observable<FileUpload>;
}

@Injectable()
export class FileUploadService {
	private subjectUploadStarted = new Subject<FileUploadStartedEvent>();

	constructor(private apiClient: ApiService, private updateMessage: UpdateMessageService, private dialog: MatDialog) { }

	afterUploadStarted(): Observable<FileUploadStartedEvent> {
		return this.subjectUploadStarted.asObservable();
	}

	uploadFile(file: File, formId: number): Observable<FileUpload> {
		const fileName = file.name;

		var fileUpload: FileUpload = {
			file: file,
			fileName: fileName,
			progress: 0,
			completed: false,
			error: null,
		};

		const subjectUploadProgress = new Subject<FileUpload>();
		this.subjectUploadStarted.next({ upload: fileUpload, upload$: subjectUploadProgress });
		subjectUploadProgress.next(fileUpload);

		// Force the content type to application/octet-stream,
		// as the generated client uses the file's type during upload,
		// instead of the type configured in the openapi spec,
		// and does not support overriding it anywhere.
		// Inconveniently, the server rejects anything not sent with an explicitly
		// mentioned content type, which means we have to specify ALL allowed types
		// in the config, or just do this here - because we don't care about the actual file type.
		var fileAsOctetStream = file.slice(0, file.size, "application/octet-stream");

		var upload$ = this.apiClient.uploadAttachment({ formId: formId, fileName: file.name, body: fileAsOctetStream });

		// TODO: The generated client does not support enabling upload progress.
		// See https://blog.angular-university.io/angular-file-upload/
		// TODO: Find alternative which supports progress reporting
		upload$.subscribe(response => {
			fileUpload.progress = 100;
			fileUpload.completed = true;
			subjectUploadProgress.next(fileUpload);

			this.updateMessage.sendFileChangedMessage(formId);
		});

		return subjectUploadProgress.asObservable();
	}
}
