import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AttachmentId, DraftAttachment, Form, FormField, FormId, FormSubmission, OperationList, StringValue, Value } from '../api/models';
import { ApiService } from '../api/services';
import { Operations } from '../operations';
import { UpdateMessageService } from '../services/update-message.service';

@Component({
  selector: 'wrplatform-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  form!: FormGroup;

  formData: Form | null = null;
  formID: FormId = 0;
  error: any;
  errorMessage: string = "";
  attachmentError: any;
  attachmentErrorMessage: string = "";
  // TODO: Extract class + component for error message display
  submitError: any;
  submitErrorMessage: string = "";
  submitInProgress: boolean = false;
  submittedWorkItem: FormSubmission | null = null;

  fieldTypeString = "string"
  fieldTypeMultilineString = "multiline_string"
  fieldTypeDate = "date"
  fieldTypeCheckbox = "checkbox"

  availableAttachments: DraftAttachment[] = [];

  me: OperationList = { operations: [] };

  Operations = Operations;

  constructor(private apiClient: ApiService, private updateMessageService: UpdateMessageService) { }

  ngOnInit(): void {
    this.form = new FormGroup([]);

    this.updateMessageService.getMessage().subscribe(message => {
      if (message.formId == this.formID) {
        this.refreshAvailableAttachments();
      }
    });

    this.refresh();
  }

  onSubmit() {
    this.submitInProgress = true;
    this.submittedWorkItem = null;
    this.submitError = null;
    this.submitErrorMessage = "";

    var submitValues: Value[] = [];


    this.formData!.fields.forEach((field, index) => {
      var control = this.form.controls["field-" + index];
      var value = control.getRawValue();
      if (value == null) {
        submitValues.push({
          type: 'NullValue',
        });
        return;
      }

      switch (field.fieldType) {
        case 'string':
        case 'multiline_string':
          submitValues.push({
            type: 'StringValue',
            value: control.getRawValue(),
          });
          break;
        case 'date':
          submitValues.push({
            type: 'DateValue',
            value: control.getRawValue(),
          });
          break;
        case 'checkbox':
          submitValues.push({
            type: 'CheckboxValue',
            value: control.getRawValue(),
          });
          break;
      }
    })


    this.apiClient.submitForm({
      formId: this.formID,
      body: {
        values: submitValues,
        attachments: this.availableAttachments.map(a => a.attachmentID),
      }
    }).subscribe({
      next: requestData => {
        console.log("Request data received:", requestData);
        this.submitInProgress = false;
        this.submittedWorkItem = requestData;
        this.submitError = null;
        this.submitErrorMessage = "";
      },
      error: (error: any) => {
        if (error instanceof HttpErrorResponse) {
          this.submitErrorMessage = error.message;
        } else {
          this.submitErrorMessage = "Unknown Error";
        }
        this.submitInProgress = false;
        this.submittedWorkItem = null;
        this.submitError = {
          "error": error,
          "formData": this.formData,
          "availableAttachments": this.availableAttachments,
          "formID": this.formID,
          "timestamp": Date(),
        };
      },
    });
    console.log("Form submitted", this.form.getRawValue())
  }

  @Input()
  set id(formID: FormId) {
    this.formID = formID;
    this.refresh();
  }

  getFields(): FormField[] {
    return this.formData?.fields ?? [];
  }

  hasOperation(operation: string): boolean {
    return this.me.operations.includes(operation);
  }

  refresh(): void {
    this.apiClient.getForm({ formId: this.formID })
      .subscribe({
        next: formData => {
          this.formData = formData;
          this.error = null;
          this.errorMessage = "";


          const controls: any = {};

          this.formData.fields.forEach((field, index) => {
            controls["field-" + index] = field.mandatory ? new FormControl(null, Validators.required) : new FormControl();
          })
          this.form = new FormGroup(controls);
        },
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = "Unknown Error";
          }
          this.formData = null;
          this.error = {
            "error": error,
            "formData": null,
            "formID": this.formID,
            "timestamp": Date(),
          };
        },
      });
    // TODO: Error handling:
    this.apiClient.getMeOperations().subscribe(me => {
      this.me = me;
    });

    this.refreshAvailableAttachments();
  }

  deleteAttachment(id: AttachmentId): void {
    this.apiClient.deleteAttachment({ attachmentId: id })
      .subscribe({
        next: () => {
          var index = this.availableAttachments.findIndex(a => a.attachmentID == id);
          if (index >= 0) {
            this.availableAttachments.splice(index, 1);
          }
          this.refreshAvailableAttachments();
        },
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.attachmentErrorMessage = error.message;
          } else {
            this.attachmentErrorMessage = "Unknown Error";
          }
          this.attachmentError = {
            "error": error,
            "formID": this.formID,
            "attachmentID": id,
            "timestamp": Date(),
          };
        }
      });
  }

  refreshAvailableAttachments(): void {
    this.apiClient.getAttachments({ formId: this.formID })
      .subscribe({
        next: attachmentList => {
          this.availableAttachments = attachmentList.attachments;
          this.attachmentError = null;
          this.attachmentErrorMessage = "";
        },
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.attachmentErrorMessage = error.message;
          } else {
            this.attachmentErrorMessage = "Unknown Error";
          }
          this.availableAttachments = [];
          this.attachmentError = {
            "error": error,
            "formID": this.formID,
            "timestamp": Date(),
          };
        }
      });
  }
}
