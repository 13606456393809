<div class="title">
    <h1>Work Request Forms</h1>
    <button mat-raised-button (click)="refresh()"><mat-icon>refresh</mat-icon>Refresh</button>
    <button mat-raised-button [hidden]="!hasOperation(Operations.CreateGroup)"
        [routerLink]="['/groups', 0, 'edit']"><mat-icon>add</mat-icon>Create new group</button>
    <button mat-raised-button [hidden]="!hasOperation(Operations.CreateForm)"
        [routerLink]="['/forms', 0, 'edit']"><mat-icon>add</mat-icon>Create new form</button>
</div>

<main>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <ng-container *ngIf="!loading">

        <div *ngFor="let formGroup of getGroups()">
            <div role="region">
                <div class="title">
                    <h2>{{formGroup.title}}</h2>
                    <button mat-raised-button [hidden]="!hasOperation(Operations.UpdateGroup)"
                        [routerLink]="['/groups', formGroup.id, 'edit']"><mat-icon>edit</mat-icon>Edit Group</button>
                    <button mat-raised-button [hidden]="!hasOperation(Operations.DeleteGroup)"
                        [mtBasicSpinner]="isDeletingFormGroup(formGroup.id)"
                        (click)="deleteFormGroup(formGroup.id)"><mat-icon>delete</mat-icon>Delete Group</button>
                    <p class="error" *ngIf="getFormGroupMessage(formGroup.id)">
                        {{getFormGroupMessage(formGroup.id)?.message}}
                    </p>
                </div>
                <div class="formgroup-description" [innerHTML]="formGroup.description | safeHtml"></div>
                <ng-container *ngFor="let formData of getForms(formGroup.id)">
                    <mat-card role="region">
                        <mat-card-header>
                            <mat-card-title>
                                <h3>{{formData.title}}</h3>
                                <button mat-raised-button
                                    [routerLink]="['/forms', formData.id]"><mat-icon>send</mat-icon>Request</button>
                                <button mat-raised-button [hidden]="!hasOperation(Operations.UpdateForm)"
                                    [routerLink]="['/forms', formData.id, 'edit']"><mat-icon>edit</mat-icon>Edit
                                    Form</button>
                                <button mat-raised-button [hidden]="!hasOperation(Operations.DeleteForm)"
                                    (click)="deleteForm(formData.id)"
                                    [mtBasicSpinner]="isDeletingForm(formData.id)"><mat-icon>delete</mat-icon>Delete
                                    Form</button>
                                <p class="error" *ngIf="getFormMessage(formData.id)">
                                    {{getFormMessage(formData.id)?.message}}
                                </p>
                            </mat-card-title>

                        </mat-card-header>
                        <mat-card-content>
                            <div class="form-description" [innerHTML]="formData.description | safeHtml"></div>
                        </mat-card-content>
                    </mat-card>
                </ng-container>
            </div>
        </div>

    </ng-container>

</main>
