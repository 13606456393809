import { Component, Input, OnInit } from '@angular/core';

import { FileUploadService } from '../services/file-upload.service';

@Component({
    selector: 'wrplatform-file-upload-button',
    templateUrl: 'file-upload-button.component.html',
    styleUrls: ["file-upload-button.component.css"]
})

export class FileUploadButtonComponent implements OnInit {
    @Input() formId: number | null = null;

    constructor(private fileUploadService: FileUploadService) { }

    ngOnInit() { }

    async onFileSelected(event: Event) {
        if (!this.formId) {
            return;
        }

        const input = event.target as HTMLInputElement;
        const file: File = input!.files![0];

        this.fileUploadService.uploadFile(file, this.formId);
    }
}
