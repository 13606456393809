/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assignUserRole } from '../fn/operations/assign-user-role';
import { AssignUserRole$Params } from '../fn/operations/assign-user-role';
import { blockBackgroundProcess } from '../fn/operations/block-background-process';
import { BlockBackgroundProcess$Params } from '../fn/operations/block-background-process';
import { createForm } from '../fn/operations/create-form';
import { CreateForm$Params } from '../fn/operations/create-form';
import { createFormGroup } from '../fn/operations/create-form-group';
import { CreateFormGroup$Params } from '../fn/operations/create-form-group';
import { deleteAttachment } from '../fn/operations/delete-attachment';
import { DeleteAttachment$Params } from '../fn/operations/delete-attachment';
import { deleteForm } from '../fn/operations/delete-form';
import { DeleteForm$Params } from '../fn/operations/delete-form';
import { deleteFormGroup } from '../fn/operations/delete-form-group';
import { DeleteFormGroup$Params } from '../fn/operations/delete-form-group';
import { DraftAttachment } from '../models/draft-attachment';
import { DraftAttachmentList } from '../models/draft-attachment-list';
import { EventList } from '../models/event-list';
import { executeBackgroundProcess } from '../fn/operations/execute-background-process';
import { ExecuteBackgroundProcess$Params } from '../fn/operations/execute-background-process';
import { Form } from '../models/form';
import { FormGroup } from '../models/form-group';
import { FormGroupList } from '../models/form-group-list';
import { FormList } from '../models/form-list';
import { FormSubmission } from '../models/form-submission';
import { getAttachments } from '../fn/operations/get-attachments';
import { GetAttachments$Params } from '../fn/operations/get-attachments';
import { getBackgroundLog } from '../fn/operations/get-background-log';
import { GetBackgroundLog$Params } from '../fn/operations/get-background-log';
import { getBackgroundStatus } from '../fn/operations/get-background-status';
import { GetBackgroundStatus$Params } from '../fn/operations/get-background-status';
import { getForm } from '../fn/operations/get-form';
import { GetForm$Params } from '../fn/operations/get-form';
import { getFormGroup } from '../fn/operations/get-form-group';
import { GetFormGroup$Params } from '../fn/operations/get-form-group';
import { getFormGroups } from '../fn/operations/get-form-groups';
import { GetFormGroups$Params } from '../fn/operations/get-form-groups';
import { getForms } from '../fn/operations/get-forms';
import { GetForms$Params } from '../fn/operations/get-forms';
import { getMe } from '../fn/operations/get-me';
import { GetMe$Params } from '../fn/operations/get-me';
import { getMeOperations } from '../fn/operations/get-me-operations';
import { GetMeOperations$Params } from '../fn/operations/get-me-operations';
import { getRoles } from '../fn/operations/get-roles';
import { GetRoles$Params } from '../fn/operations/get-roles';
import { getUser } from '../fn/operations/get-user';
import { GetUser$Params } from '../fn/operations/get-user';
import { getUserOperations } from '../fn/operations/get-user-operations';
import { GetUserOperations$Params } from '../fn/operations/get-user-operations';
import { getUsers } from '../fn/operations/get-users';
import { GetUsers$Params } from '../fn/operations/get-users';
import { OperationList } from '../models/operation-list';
import { RoleList } from '../models/role-list';
import { Status } from '../models/status';
import { StatusList } from '../models/status-list';
import { submitForm } from '../fn/operations/submit-form';
import { SubmitForm$Params } from '../fn/operations/submit-form';
import { unassignUserRole } from '../fn/operations/unassign-user-role';
import { UnassignUserRole$Params } from '../fn/operations/unassign-user-role';
import { unblockBackgroundProcess } from '../fn/operations/unblock-background-process';
import { UnblockBackgroundProcess$Params } from '../fn/operations/unblock-background-process';
import { updateForm } from '../fn/operations/update-form';
import { UpdateForm$Params } from '../fn/operations/update-form';
import { updateFormGroup } from '../fn/operations/update-form-group';
import { UpdateFormGroup$Params } from '../fn/operations/update-form-group';
import { uploadAttachment } from '../fn/operations/upload-attachment';
import { UploadAttachment$Params } from '../fn/operations/upload-attachment';
import { User } from '../models/user';
import { UserList } from '../models/user-list';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFormGroups()` */
  static readonly GetFormGroupsPath = '/formgroups';

  /**
   * Get a list of form groups visible to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFormGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormGroups$Response(params?: GetFormGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<FormGroupList>> {
    return getFormGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of form groups visible to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFormGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormGroups(params?: GetFormGroups$Params, context?: HttpContext): Observable<FormGroupList> {
    return this.getFormGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<FormGroupList>): FormGroupList => r.body)
    );
  }

  /** Path part for operation `createFormGroup()` */
  static readonly CreateFormGroupPath = '/formgroups';

  /**
   * Create a new form group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFormGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFormGroup$Response(params?: CreateFormGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<FormGroup>> {
    return createFormGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new form group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFormGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFormGroup(params?: CreateFormGroup$Params, context?: HttpContext): Observable<FormGroup> {
    return this.createFormGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<FormGroup>): FormGroup => r.body)
    );
  }

  /** Path part for operation `getFormGroup()` */
  static readonly GetFormGroupPath = '/formgroups/{formGroupId}';

  /**
   * Get a form group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFormGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormGroup$Response(params: GetFormGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<FormGroup>> {
    return getFormGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a form group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFormGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormGroup(params: GetFormGroup$Params, context?: HttpContext): Observable<FormGroup> {
    return this.getFormGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<FormGroup>): FormGroup => r.body)
    );
  }

  /** Path part for operation `updateFormGroup()` */
  static readonly UpdateFormGroupPath = '/formgroups/{formGroupId}';

  /**
   * Update a form group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFormGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormGroup$Response(params: UpdateFormGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<FormGroup>> {
    return updateFormGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a form group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFormGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormGroup(params: UpdateFormGroup$Params, context?: HttpContext): Observable<FormGroup> {
    return this.updateFormGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<FormGroup>): FormGroup => r.body)
    );
  }

  /** Path part for operation `deleteFormGroup()` */
  static readonly DeleteFormGroupPath = '/formgroups/{formGroupId}';

  /**
   * Delete a form group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFormGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFormGroup$Response(params: DeleteFormGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return deleteFormGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a form group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFormGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFormGroup(params: DeleteFormGroup$Params, context?: HttpContext): Observable<{
}> {
    return this.deleteFormGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `getForms()` */
  static readonly GetFormsPath = '/forms';

  /**
   * Get a list of forms visible to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForms$Response(params?: GetForms$Params, context?: HttpContext): Observable<StrictHttpResponse<FormList>> {
    return getForms(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of forms visible to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForms(params?: GetForms$Params, context?: HttpContext): Observable<FormList> {
    return this.getForms$Response(params, context).pipe(
      map((r: StrictHttpResponse<FormList>): FormList => r.body)
    );
  }

  /** Path part for operation `createForm()` */
  static readonly CreateFormPath = '/forms';

  /**
   * Create a new form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createForm$Response(params?: CreateForm$Params, context?: HttpContext): Observable<StrictHttpResponse<Form>> {
    return createForm(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new form.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createForm(params?: CreateForm$Params, context?: HttpContext): Observable<Form> {
    return this.createForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<Form>): Form => r.body)
    );
  }

  /** Path part for operation `getForm()` */
  static readonly GetFormPath = '/forms/{formId}';

  /**
   * Get a form by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForm()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForm$Response(params: GetForm$Params, context?: HttpContext): Observable<StrictHttpResponse<Form>> {
    return getForm(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a form by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForm(params: GetForm$Params, context?: HttpContext): Observable<Form> {
    return this.getForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<Form>): Form => r.body)
    );
  }

  /** Path part for operation `updateForm()` */
  static readonly UpdateFormPath = '/forms/{formId}';

  /**
   * Update a form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateForm$Response(params: UpdateForm$Params, context?: HttpContext): Observable<StrictHttpResponse<Form>> {
    return updateForm(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a form.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateForm(params: UpdateForm$Params, context?: HttpContext): Observable<Form> {
    return this.updateForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<Form>): Form => r.body)
    );
  }

  /** Path part for operation `deleteForm()` */
  static readonly DeleteFormPath = '/forms/{formId}';

  /**
   * Delete a form.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteForm()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteForm$Response(params: DeleteForm$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return deleteForm(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a form.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteForm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteForm(params: DeleteForm$Params, context?: HttpContext): Observable<{
}> {
    return this.deleteForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `submitForm()` */
  static readonly SubmitFormPath = '/forms/{formId}/submit';

  /**
   * Submit the content of a form to be processed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitForm$Response(params: SubmitForm$Params, context?: HttpContext): Observable<StrictHttpResponse<FormSubmission>> {
    return submitForm(this.http, this.rootUrl, params, context);
  }

  /**
   * Submit the content of a form to be processed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitForm(params: SubmitForm$Params, context?: HttpContext): Observable<FormSubmission> {
    return this.submitForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<FormSubmission>): FormSubmission => r.body)
    );
  }

  /** Path part for operation `getAttachments()` */
  static readonly GetAttachmentsPath = '/attachments';

  /**
   * Get available attachments for the current user which can be used in form submission.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachments$Response(params: GetAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<DraftAttachmentList>> {
    return getAttachments(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available attachments for the current user which can be used in form submission.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachments(params: GetAttachments$Params, context?: HttpContext): Observable<DraftAttachmentList> {
    return this.getAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<DraftAttachmentList>): DraftAttachmentList => r.body)
    );
  }

  /** Path part for operation `uploadAttachment()` */
  static readonly UploadAttachmentPath = '/attachments';

  /**
   * Upload an attachment to be used in form submission later.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAttachment()` instead.
   *
   * This method sends `application/octet-stream` and handles request body of type `application/octet-stream`.
   */
  uploadAttachment$Response(params: UploadAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<DraftAttachment>> {
    return uploadAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload an attachment to be used in form submission later.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAttachment$Response()` instead.
   *
   * This method sends `application/octet-stream` and handles request body of type `application/octet-stream`.
   */
  uploadAttachment(params: UploadAttachment$Params, context?: HttpContext): Observable<DraftAttachment> {
    return this.uploadAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<DraftAttachment>): DraftAttachment => r.body)
    );
  }

  /** Path part for operation `deleteAttachment()` */
  static readonly DeleteAttachmentPath = '/attachments/{attachmentId}';

  /**
   * Delete an attachment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachment$Response(params: DeleteAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return deleteAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an attachment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachment(params: DeleteAttachment$Params, context?: HttpContext): Observable<{
}> {
    return this.deleteAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `getMe()` */
  static readonly GetMePath = '/me';

  /**
   * Get the currently logged-in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe$Response(params?: GetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return getMe(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the currently logged-in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe(params?: GetMe$Params, context?: HttpContext): Observable<User> {
    return this.getMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `getMeOperations()` */
  static readonly GetMeOperationsPath = '/me/operations';

  /**
   * Get operations allowed for the currently logged-in user, based on the assigned roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeOperations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeOperations$Response(params?: GetMeOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationList>> {
    return getMeOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get operations allowed for the currently logged-in user, based on the assigned roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMeOperations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeOperations(params?: GetMeOperations$Params, context?: HttpContext): Observable<OperationList> {
    return this.getMeOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationList>): OperationList => r.body)
    );
  }

  /** Path part for operation `getBackgroundLog()` */
  static readonly GetBackgroundLogPath = '/admin/background/log';

  /**
   * Get a list of logged events which ran in background.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBackgroundLog()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackgroundLog$Response(params?: GetBackgroundLog$Params, context?: HttpContext): Observable<StrictHttpResponse<EventList>> {
    return getBackgroundLog(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of logged events which ran in background.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBackgroundLog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackgroundLog(params?: GetBackgroundLog$Params, context?: HttpContext): Observable<EventList> {
    return this.getBackgroundLog$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventList>): EventList => r.body)
    );
  }

  /** Path part for operation `getBackgroundStatus()` */
  static readonly GetBackgroundStatusPath = '/admin/background/status';

  /**
   * Get current status of background activities.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBackgroundStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackgroundStatus$Response(params?: GetBackgroundStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<StatusList>> {
    return getBackgroundStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Get current status of background activities.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBackgroundStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackgroundStatus(params?: GetBackgroundStatus$Params, context?: HttpContext): Observable<StatusList> {
    return this.getBackgroundStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatusList>): StatusList => r.body)
    );
  }

  /** Path part for operation `blockBackgroundProcess()` */
  static readonly BlockBackgroundProcessPath = '/admin/background/status/{eventType}/block';

  /**
   * Block an background process event type from being processed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockBackgroundProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockBackgroundProcess$Response(params: BlockBackgroundProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<Status>> {
    return blockBackgroundProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * Block an background process event type from being processed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockBackgroundProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockBackgroundProcess(params: BlockBackgroundProcess$Params, context?: HttpContext): Observable<Status> {
    return this.blockBackgroundProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Status>): Status => r.body)
    );
  }

  /** Path part for operation `unblockBackgroundProcess()` */
  static readonly UnblockBackgroundProcessPath = '/admin/background/status/{eventType}/unblock';

  /**
   * Unblock a background process event type from being processed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unblockBackgroundProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  unblockBackgroundProcess$Response(params: UnblockBackgroundProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<Status>> {
    return unblockBackgroundProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * Unblock a background process event type from being processed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unblockBackgroundProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unblockBackgroundProcess(params: UnblockBackgroundProcess$Params, context?: HttpContext): Observable<Status> {
    return this.unblockBackgroundProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Status>): Status => r.body)
    );
  }

  /** Path part for operation `executeBackgroundProcess()` */
  static readonly ExecuteBackgroundProcessPath = '/admin/background/status/{eventType}/execute';

  /**
   * Explicitly trigger execution of a background process by event type. Also triggers execution if the process is currently blocked, but will not remove the block.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeBackgroundProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeBackgroundProcess$Response(params: ExecuteBackgroundProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<Status>> {
    return executeBackgroundProcess(this.http, this.rootUrl, params, context);
  }

  /**
   * Explicitly trigger execution of a background process by event type. Also triggers execution if the process is currently blocked, but will not remove the block.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeBackgroundProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeBackgroundProcess(params: ExecuteBackgroundProcess$Params, context?: HttpContext): Observable<Status> {
    return this.executeBackgroundProcess$Response(params, context).pipe(
      map((r: StrictHttpResponse<Status>): Status => r.body)
    );
  }

  /** Path part for operation `getRoles()` */
  static readonly GetRolesPath = '/admin/roles';

  /**
   * Get list of roles which exist in the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Response(params?: GetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleList>> {
    return getRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of roles which exist in the application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles(params?: GetRoles$Params, context?: HttpContext): Observable<RoleList> {
    return this.getRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleList>): RoleList => r.body)
    );
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/admin/users';

  /**
   * Get list of users available in the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserList>> {
    return getUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of users available in the application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: GetUsers$Params, context?: HttpContext): Observable<UserList> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserList>): UserList => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/admin/users/{userId}';

  /**
   * Get details of a single user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Get details of a single user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: GetUser$Params, context?: HttpContext): Observable<User> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `getUserOperations()` */
  static readonly GetUserOperationsPath = '/admin/users/{userId}/operations';

  /**
   * Get operations allowed for a single user, based on the assigned roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserOperations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserOperations$Response(params: GetUserOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationList>> {
    return getUserOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get operations allowed for a single user, based on the assigned roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserOperations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserOperations(params: GetUserOperations$Params, context?: HttpContext): Observable<OperationList> {
    return this.getUserOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationList>): OperationList => r.body)
    );
  }

  /** Path part for operation `assignUserRole()` */
  static readonly AssignUserRolePath = '/admin/users/{userId}/roles/{roleName}';

  /**
   * Assign a role to a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignUserRole$Response(params: AssignUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return assignUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Assign a role to a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignUserRole(params: AssignUserRole$Params, context?: HttpContext): Observable<User> {
    return this.assignUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `unassignUserRole()` */
  static readonly UnassignUserRolePath = '/admin/users/{userId}/roles/{roleName}';

  /**
   * Unassign a role from a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignUserRole$Response(params: UnassignUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return unassignUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Unassign a role from a user. Returns the new user object after modification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unassignUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignUserRole(params: UnassignUserRole$Params, context?: HttpContext): Observable<User> {
    return this.unassignUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

}
