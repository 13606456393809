import { Component, OnInit } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormGroupId, FormGroupList, FormHeader, FormId, FormList, OperationList } from '../api/models';
import { ApiService } from '../api/services';
import { Operations } from '../operations';

interface IdError {
  int: number;
  message: string;
}

@Component({
  selector: 'wrplatform-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.css']
})
export class FormListComponent implements OnInit {
  groupList: FormGroupList = { groups: [] };
  formList: FormList = { forms: [] };
  me: OperationList = { operations: [] };

  Operations = Operations;

  loading: boolean = false;

  deletingForms: number[] = [];
  deletingFormGroups: number[] = [];
  messagesForForms: IdError[] = [];
  messagesForFormGroups: IdError[] = [];

  constructor(private apiClient: ApiService) { }

  ngOnInit(): void {
    this.refresh();
  }

  getGroups(): FormGroup[] {
    // Fallback if the backend returns any forms without a matching group
    var needUngrouped = false;
    for (const form of this.formList.forms) {
      if (form.group == 0 || this.groupList.groups.find(g => g.id == form.group) === undefined) {
        needUngrouped = true;
        // Ensure the form is picked up by the "ungrouped" pseudo group
        form.group = 0;
      }
    }
    if (needUngrouped) {
      return this.groupList.groups.concat({ id: 0, title: "Other", description: "These forms are not assigned to any category." })
    }
    return this.groupList.groups;
  }

  getForms(group: FormGroupId): FormHeader[] {
    return this.formList.forms.filter(f => f.group == group);
  }

  getFormMessage(form: FormId): IdError | null {
    return this.messagesForForms.find(msg => msg.int == form) ?? null;
  }

  getFormGroupMessage(formGroup: FormGroupId): IdError | null {
    return this.messagesForFormGroups.find(msg => msg.int == formGroup) ?? null;
  }

  hasOperation(operation: string): boolean {
    return this.me.operations.includes(operation);
  }

  isDeletingForm(formId: FormId): boolean {
    return this.deletingForms.findIndex(f => f == formId) >= 0;
  }

  deleteForm(formId: FormId): void {
    this.deletingForms.push(formId);
    var idx = this.messagesForForms.findIndex(m => m.int == formId);
    if (idx >= 0) {
      this.messagesForForms.splice(idx, 1);
    }
    this.apiClient.deleteForm({ formId: formId })
      .subscribe({
        next: () => {
          var idx = this.deletingForms.findIndex(f => f == formId);
          if (idx >= 0) {
            this.deletingForms.splice(idx, 1);
          }
          idx = this.formList.forms.findIndex(f => f.id == formId);
          if (idx >= 0) {
            this.formList.forms.splice(idx, 1);
          }
        },
        error: (error: any) => {
          var idx = this.deletingForms.findIndex(f => f == formId);
          if (idx >= 0) {
            this.deletingForms.splice(idx, 1);
          }

          if (error instanceof HttpErrorResponse) {
            this.messagesForForms.push({ int: formId, message: error.message });
          } else {
            this.messagesForForms.push({ int: formId, message: "Unknown Error" });
          }
          // TODO: further details with error components
        },
      });
  }

  isDeletingFormGroup(formGroupId: FormGroupId): boolean {
    return this.deletingFormGroups.findIndex(g => g == formGroupId) >= 0;
  }

  deleteFormGroup(formGroupId: FormGroupId): void {
    this.deletingFormGroups.push(formGroupId);
    var idx = this.messagesForFormGroups.findIndex(m => m.int == formGroupId);
    if (idx >= 0) {
      this.messagesForFormGroups.splice(idx, 1);
    }
    this.apiClient.deleteFormGroup({ formGroupId: formGroupId })
      .subscribe({
        next: () => {
          var idx = this.deletingFormGroups.findIndex(f => f == formGroupId);
          if (idx >= 0) {
            this.deletingFormGroups.splice(idx, 1);
          }
          idx = this.groupList.groups.findIndex(f => f.id == formGroupId);
          if (idx >= 0) {
            this.groupList.groups.splice(idx, 1);
          }
        },
        error: (error: any) => {
          var idx = this.deletingFormGroups.findIndex(f => f == formGroupId);
          if (idx >= 0) {
            this.deletingFormGroups.splice(idx, 1);
          }

          if (error instanceof HttpErrorResponse) {
            this.messagesForFormGroups.push({ int: formGroupId, message: error.message });
          } else {
            this.messagesForFormGroups.push({ int: formGroupId, message: "Unknown Error" });
          }
          // TODO: further details with error components
        },
      });
  }

  refresh(): void {
    // TODO: Error handling

    this.loading = true;
    this.apiClient.getFormGroups().subscribe(groupList => {
      this.groupList = groupList;
      this.groupList.groups.sort((a, b) => a.title.localeCompare(b.title));
      this.apiClient.getForms().subscribe(formList => {
        this.formList = formList;
        this.formList.forms.sort((a, b) => a.title.localeCompare(b.title));
        this.loading = false;
      });
    });

    this.apiClient.getMeOperations().subscribe(me => {
      this.me = me;
    });
  }
}
