<mat-sidenav-container id="main">

  <mat-sidenav #sidenav mode="side" role="navigation">
    <nav>
      <mat-toolbar><span class="title">Navigation</span></mat-toolbar>
      <mat-list>
        <mat-list-item><a mat-button class="button" routerLink="/forms" routerLinkActive="activePage" ariaCurrentWhenActive="page">WR Forms</a></mat-list-item>
        <mat-list-item>Admin</mat-list-item>
        <mat-list-item><a mat-button class="button" routerLink="/groups" routerLinkActive="activePage" ariaCurrentWhenActive="page">Form Groups</a></mat-list-item>
        <mat-list-item><a mat-button class="button" routerLink="/admin/bgstatus" routerLinkActive="activePage" ariaCurrentWhenActive="page">Background Status</a></mat-list-item>
        <mat-list-item><a mat-button class="button" routerLink="/admin/users" routerLinkActive="activePage" ariaCurrentWhenActive="page">Users</a></mat-list-item>
      </mat-list>
    </nav>
  </mat-sidenav>

  <mat-sidenav-content role="main">
    <mat-toolbar role="heading">
      <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
      <span class="title">WR Platform</span>

      <div class="toolbar-spacer"></div>

      <span *ngIf="loginService.isAuthenticated">
        <mat-icon>user</mat-icon>
        {{loginService.activeAccount?.name}} |
        <a mat-button [routerLink]="['profile']">Profile</a>
      </span>

      <button mat-raised-button (click)="loginRedirect()" *ngIf="!loginService.isAuthenticated">Login</button>
      <button mat-raised-button (click)="logout()" *ngIf="loginService.isAuthenticated">Logout</button>
    </mat-toolbar>

    <div class="mainMargin">
      <wrplatform-file-upload-list></wrplatform-file-upload-list>

      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>

</mat-sidenav-container>
