import { Component } from '@angular/core';
import { LoginService } from '../services/login.service';



@Component({
  selector: 'wrplatform-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css']
})
export class ProfilePageComponent {
  constructor(
    public loginService: LoginService
  ) { }

}
