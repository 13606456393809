<div class="title">
	<h1>Users</h1>
	<button mat-raised-button (click)="refresh()"><mat-icon>refresh</mat-icon>Refresh</button>
</div>

<mat-card role="region">
	<mat-card-content>
		<table mat-table [dataSource]="dataSourceUsers" aria-label="User List">
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef>ID</th>
				<td mat-cell *matCellDef="let element">{{element.id}}</td>
			</ng-container>

			<ng-container matColumnDef="aadId">
				<th mat-header-cell *matHeaderCellDef>AAD ID</th>
				<td mat-cell *matCellDef="let element">{{element.aadId}}</td>
			</ng-container>

			<ng-container matColumnDef="roles">
				<th mat-header-cell *matHeaderCellDef>Roles</th>
				<td mat-cell *matCellDef="let element">
					<mat-chip-set>
						<mat-chip *ngFor="let role of element.roles" (removed)="unassignRole(element.id, role)">
							{{role}}

							<button matChipRemove aria-label="Unassign role" *ngIf="isRoleAssignable(role)">
								<mat-icon>cancel</mat-icon>
							</button>
						</mat-chip>
					</mat-chip-set>
					<button mat-button [matMenuTriggerFor]="addRoleMenu"
						[matMenuTriggerData]="{userId: element.id}"><mat-icon>add</mat-icon> Assign Role</button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumnsUsers"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumnsUsers"></tr>
		</table>
		<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of users" />

		<mat-menu #addRoleMenu="matMenu">
			<ng-template matMenuContent let-userId="userId">
				<button (click)="assignRole(userId, role.name)" mat-menu-item *ngFor="let role of getAssignableRoles()"
					aria-label="Assign role to user">
					{{role.displayName}}
				</button>
			</ng-template>
		</mat-menu>
	</mat-card-content>
</mat-card>
