<div class="title">
    <h1>Form Groups</h1>
    <button mat-raised-button (click)="refresh()"><mat-icon>refresh</mat-icon>Refresh</button>
    <button mat-raised-button [hidden]="!hasOperation(Operations.CreateGroup)"
        [routerLink]="['/groups', 0, 'edit']"><mat-icon>add</mat-icon>Create new group</button>
</div>

<main>

</main>
<div *ngFor="let formGroup of getGroups()">
    <div role="region">
        <div class="title">
            <h2>{{formGroup.title}}</h2>
            <button mat-raised-button [hidden]="!hasOperation(Operations.UpdateGroup)"
                [routerLink]="['/groups', formGroup.id, 'edit']"><mat-icon>edit</mat-icon>Edit Group</button>
            <button mat-raised-button [hidden]="!hasOperation(Operations.DeleteGroup)"
                [mtBasicSpinner]="isDeletingFormGroup(formGroup.id)"
                (click)="deleteFormGroup(formGroup.id)"><mat-icon>delete</mat-icon>Delete Group</button>
            <p class="error" *ngIf="getFormGroupMessage(formGroup.id)">
                {{getFormGroupMessage(formGroup.id)?.message}}
        </div>
        <div class="formgroup-description" [innerHTML]="formGroup.description | safeHtml"></div>
    </div>
</div>
