import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountInfo } from "@azure/msal-common";
import { environment } from 'src/environments/environment';

export type ProfileInfo = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  /**
   * Whether a local login session is found.
   * Used to display appropriate controls, not used for actual security (That is done in the backend).
   */
  isAuthenticated: boolean = false;
  activeAccount: AccountInfo | null = null;
  profileInfo: ProfileInfo | null = null;

  constructor(
    private http: HttpClient,
  ) { }

  setActiveAccount(activeAccount: AccountInfo | null) {
    this.activeAccount = activeAccount;
    this.isAuthenticated = activeAccount != null;

    if (this.isAuthenticated) {
      if (environment.enableMsal) {
        // this.http.get(environment.profileApiConfig.uri)
        //   .subscribe(profile => {
        //     this.profileInfo = profile;
        //   });
        this.profileInfo = {
          givenName: "", // Test (not available from active account)
          surname: "", // User (not available from active account)
          id: activeAccount?.username, // UsernamE@BASFAD.BASF.NET
          userPrincipalName: activeAccount?.name, // Test User
        };
      } else {
        this.profileInfo = {
          givenName: "Test",
          surname: "User",
          id: "localuser@test.basfad.basf.net",
          userPrincipalName: "Test User",
        };
      }
    } else {
      this.profileInfo = null;
    }
  }
}
