/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DraftAttachment } from '../../models/draft-attachment';
import { FormId } from '../../models/form-id';

export interface UploadAttachment$Params {

/**
 * Form ID
 */
  formId: FormId;

/**
 * Display File Name. Max length
 */
  fileName: string;
      body: Blob
}

export function uploadAttachment(http: HttpClient, rootUrl: string, params: UploadAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<DraftAttachment>> {
  const rb = new RequestBuilder(rootUrl, uploadAttachment.PATH, 'put');
  if (params) {
    rb.query('formId', params.formId, {});
    rb.query('fileName', params.fileName, {});
    rb.body(params.body, 'application/octet-stream');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DraftAttachment>;
    })
  );
}

uploadAttachment.PATH = '/attachments';
