import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupId, FormGroupList, OperationList } from '../api/models';
import { ApiService } from '../api/services';
import { Operations } from '../operations';

interface IdError {
  int: number;
  message: string;
}

@Component({
  selector: 'wrplatform-formgroup-list',
  templateUrl: './formgroup-list.component.html',
  styleUrls: ['./formgroup-list.component.css']
})
export class FormgroupListComponent implements OnInit {
  groupList: FormGroupList = { groups: [] };
  me: OperationList = { operations: [] };

  Operations = Operations;

  deletingFormGroups: number[] = [];
  messagesForFormGroups: IdError[] = [];

  constructor(private apiClient: ApiService) { }

  ngOnInit(): void {
    this.refresh();
  }

  getGroups(): FormGroup[] {
    return this.groupList.groups;
  }

  getFormGroupMessage(formGroup: FormGroupId): IdError | null {
    return this.messagesForFormGroups.find(msg => msg.int == formGroup) ?? null;
  }

  isDeletingFormGroup(formGroupId: FormGroupId): boolean {
    return this.deletingFormGroups.findIndex(g => g == formGroupId) >= 0;
  }

  deleteFormGroup(formGroupId: FormGroupId): void {
    this.deletingFormGroups.push(formGroupId);
    var idx = this.messagesForFormGroups.findIndex(m => m.int == formGroupId);
    if (idx >= 0) {
      this.messagesForFormGroups.splice(idx, 1);
    }
    this.apiClient.deleteFormGroup({ formGroupId: formGroupId })
      .subscribe({
        next: () => {
          var idx = this.deletingFormGroups.findIndex(f => f == formGroupId);
          if (idx >= 0) {
            this.deletingFormGroups.splice(idx, 1);
          }
          idx = this.groupList.groups.findIndex(f => f.id == formGroupId);
          if (idx >= 0) {
            this.groupList.groups.splice(idx, 1);
          }
        },
        error: (error: any) => {
          var idx = this.deletingFormGroups.findIndex(f => f == formGroupId);
          if (idx >= 0) {
            this.deletingFormGroups.splice(idx, 1);
          }

          if (error instanceof HttpErrorResponse) {
            this.messagesForFormGroups.push({ int: formGroupId, message: error.message });
          } else {
            this.messagesForFormGroups.push({ int: formGroupId, message: "Unknown Error" });
          }
          // TODO: further details with error components
        },
      });
  }

  hasOperation(operation: string): boolean {
    return this.me.operations.includes(operation);
  }

  refresh(): void {
    // TODO: Error handling
    this.apiClient.getFormGroups().subscribe(groupList => {
      this.groupList = groupList;
      this.groupList.groups.sort((a, b) => a.title.localeCompare(b.title));
    });
    this.apiClient.getMeOperations().subscribe(me => {
      this.me = me;
    });
  }

}
