<div *ngIf="!formData">
    <div class="title">
        <button mat-icon-button [routerLink]="'..'"><mat-icon>chevron_left</mat-icon></button>
        <h1>Form not available</h1>
    </div>

    <mat-card role="region">
        <mat-card-content>
            <p>This form is not available. Please contact support and include this message.</p>
            <p *ngIf="error">{{errorMessage}}</p>
            <p>Additional support information:</p>
            <code><pre>{{error | json}}</pre></code>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="formData">
    <div class="title">
        <button mat-icon-button [routerLink]="'..'"><mat-icon>chevron_left</mat-icon></button>
        <h1>{{formData.title}}</h1>
        <button mat-raised-button [hidden]="!hasOperation(Operations.UpdateForm)"
            [routerLink]="['/forms', formData.id, 'edit']"><mat-icon>edit</mat-icon>Edit Form</button>
    </div>


    <ng-form (ngSubmit)="onSubmit" [formGroup]="form">

        <mat-card role="region">
            <mat-card-content>
                <div class="form-description" [innerHTML]="formData.description | safeHtml"></div>
            </mat-card-content>
        </mat-card>

        <ng-container *ngIf="!submitInProgress && !submittedWorkItem">

            <div role="region">
                <div *ngFor="let field of getFields(); index as i">
                    <mat-card>
                        <mat-card-content>
                            <div id="field-{{i}}" class="field" [ngSwitch]="field.fieldType">

                                <ng-container *ngSwitchCase="fieldTypeString">
                                    <mat-form-field>
                                        <mat-label>{{field.title}}</mat-label>
                                        <input matInput type="text" [formControlName]="'field-'+i"
                                            [required]="field.mandatory" aria-describedby="field-description-{{i}}" />
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngSwitchCase="fieldTypeMultilineString">
                                    <mat-form-field>
                                        <mat-label>{{field.title}}</mat-label>
                                        <textarea matInput [formControlName]="'field-'+i" [required]="field.mandatory"
                                            aria-describedby="field-description-{{i}}">
                                            </textarea>
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngSwitchCase="fieldTypeDate">
                                    <mat-form-field>
                                        <mat-label>{{field.title}}</mat-label>
                                        <input matInput type="date" [formControlName]="'field-'+i"
                                            [required]="field.mandatory" aria-describedby="field-description-{{i}}" />
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngSwitchCase="fieldTypeCheckbox">
                                    <mat-checkbox *ngSwitchCase="fieldTypeCheckbox" [formControlName]="'field-'+i"
                                        [required]="field.mandatory"
                                        aria-describedby="field-description-{{i}}">{{field.title}}</mat-checkbox>
                                </ng-container>


                            </div>
                            <div id="field-description-{{i}}" class="field-description"
                                *ngIf="field.description.length > 0" [innerHTML]="field.description | safeHtml">
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <mat-card role="region">
                <mat-card-title>
                    <h2>Attachments</h2>
                </mat-card-title>
                <mat-card-content>
                    <div *ngIf="attachmentError">
                        <p>Error loading available attachments. Please contact support and include this message.</p>
                        <p>{{attachmentErrorMessage}}</p>
                        <p>Additional support information:</p>
                        <code><pre>{{attachmentError | json}}</pre></code>
                    </div>
                    <div *ngFor="let attachment of availableAttachments">
                        <b>{{attachment.fileName}}</b>
                        <button mat-icon-button
                            (click)="deleteAttachment(attachment.attachmentID)"><mat-icon>delete</mat-icon></button>
                    </div>
                    <wrplatform-file-upload-button [formId]="formID" />
                </mat-card-content>
            </mat-card>

        </ng-container>

        <mat-card role="region">
            <mat-card-content>
                <div>
                    <button mat-raised-button type="submit" [disabled]="!form.valid" (click)="onSubmit()"
                        *ngIf="!submitInProgress && !submittedWorkItem">Submit
                        Request</button>
                    <p *ngIf="submitInProgress">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        Creating AzureDevOps WorkItem for you... Please wait.
                    </p>
                    <p *ngIf="submittedWorkItem">
                        An AzureDevOps WorkItem was created for you. You can follow the progress here:<br /> <a
                            [href]="submittedWorkItem.workItemURL" target="_blank">Azure DevOps WorkItem
                            {{submittedWorkItem.workItemID}}</a>
                    </p>
                    <div *ngIf="submitError">
                        <p>Error creating work item. Please contact support and include this message, if you are unsure
                            why this happened.</p>
                        <p>{{submitErrorMessage}}</p>
                        <p>Additional support information:</p>
                        <code><pre>{{submitError | json}}</pre></code>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </ng-form>

</div>
