import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Provider, forwardRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TimeagoModule } from "ngx-timeago";

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { environment } from '../environments/environment';
import { ApiAuthorizationInterceptor } from './api-authorization.interceptor';
import { ApiModule } from './api/api.module';
import { FileUploadService } from './services/file-upload.service';
import { UpdateMessageService } from './services/update-message.service';

import { CodeEditorModule } from '@ngstack/code-editor';
import { NgxLoadingButtonsModule } from 'ngx-loading-buttons';
import { AppComponent } from './app.component';
import { BackgroundStatusComponent } from './background-status/background-status.component';
import { FileUploadButtonComponent } from './file-upload-button/file-upload-button.component';
import { FileUploadListComponent } from './file-upload-list/file-upload-list.component';
import { FormEditComponent } from './form-edit/form-edit.component';
import { FormListComponent } from './form-list/form-list.component';
import { FormComponent } from './form/form.component';
import { FormgroupEditComponent } from './formgroup-edit/formgroup-edit.component';
import { FormgroupListComponent } from './formgroup-list/formgroup-list.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { UserListComponent } from './user-list/user-list.component';

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiAuthorizationInterceptor),
  multi: true,
};

export const UPDATE_MESSAGE_SERVICE_PROVIDER: Provider = {
  provide: UpdateMessageService,
  useClass: UpdateMessageService,
  multi: false,
};

export const FILE_UPLOAD_SERVICE_PROVIDER: Provider = {
  provide: FileUploadService,
  useClass: FileUploadService,
  multi: false,
};

export const MSAL_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: MsalInterceptor,
  multi: true,
};

/**
 * Conditionally enable MsalGuard depending on if we are in local development or in a deployed application.
 */
const canActivate = environment.enableMsal ? [MsalGuard] : [];

@NgModule({
  declarations: [
    AppComponent,
    BackgroundStatusComponent,
    FileUploadButtonComponent,
    FileUploadListComponent,
    ProfilePageComponent,
    UserListComponent,
    FormListComponent,
    SafeHtmlPipe,
    FormComponent,
    FormgroupListComponent,
    FormgroupEditComponent,
    FormEditComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/forms', pathMatch: 'full' },
      { path: 'forms', component: FormListComponent, canActivate: canActivate },
      { path: 'forms/:id', component: FormComponent, canActivate: canActivate },
      { path: 'forms/:id/edit', component: FormEditComponent, canActivate: canActivate },
      { path: 'groups', component: FormgroupListComponent, canActivate: canActivate },
      { path: 'groups/:id/edit', component: FormgroupEditComponent, canActivate: canActivate },
      { path: 'profile', component: ProfilePageComponent, canActivate: canActivate },
      { path: 'admin/bgstatus', component: BackgroundStatusComponent, canActivate: canActivate },
      { path: 'admin/users', component: UserListComponent, canActivate: canActivate },
    ], {
      useHash: true,
      bindToComponentInputs: true,
    }),
    MsalModule.forRoot(
      new PublicClientApplication({ // MSAL Configuration
        auth: {
          clientId: environment.msalConfig.auth.clientId,
          authority: environment.msalConfig.auth.authority,
          redirectUri: "/",
          postLogoutRedirectUri: "/",
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback: () => { },
            piiLoggingEnabled: false
          }
        },
      }),
      { // MSAL Guard Configuration
        interactionType: InteractionType.Redirect,
      },
      { // MSAL Interceptor Configuration
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          [environment.backendApiConfig.uri, environment.enableMsal ? ['api://' + environment.msalConfig.auth.clientId + '/wrplatform_frontend'] : []]
        ])
      }),
    TimeagoModule.forRoot(),
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    ApiModule.forRoot({ rootUrl: environment.backendApiConfig.uri }),
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    ReactiveFormsModule,
    NgxLoadingButtonsModule,
    CodeEditorModule.forRoot(),
  ],
  providers: [
    ApiAuthorizationInterceptor,
    API_INTERCEPTOR_PROVIDER,
    MSAL_INTERCEPTOR_PROVIDER,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    UPDATE_MESSAGE_SERVICE_PROVIDER,
    FILE_UPLOAD_SERVICE_PROVIDER,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
