<input type="file" class="file-input" (change)="onFileSelected($event)" #fileInput>
<!--accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx"-->

<div class="file-upload">

    <button mat-raised-button color="primary" (click)="fileInput.click()" aria-label="Upload new content for data set"
        title="Upload content">
        <mat-icon>upload</mat-icon> Upload File
    </button>
</div>
