/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FormId } from '../../models/form-id';
import { FormSubmission } from '../../models/form-submission';
import { FormSubmissionData } from '../../models/form-submission-data';

export interface SubmitForm$Params {

/**
 * Form ID
 */
  formId: FormId;
      body?: FormSubmissionData
}

export function submitForm(http: HttpClient, rootUrl: string, params: SubmitForm$Params, context?: HttpContext): Observable<StrictHttpResponse<FormSubmission>> {
  const rb = new RequestBuilder(rootUrl, submitForm.PATH, 'post');
  if (params) {
    rb.path('formId', params.formId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FormSubmission>;
    })
  );
}

submitForm.PATH = '/forms/{formId}/submit';
