import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

export interface FileChangedMessage {
	formId: number;
}

@Injectable()
export class UpdateMessageService {
	private subject = new Subject<FileChangedMessage>();

	sendFileChangedMessage(formId: number) {
		this.subject.next({ formId: formId });
	}

	getMessage(): Observable<FileChangedMessage> {
		return this.subject.asObservable();
	}
}
