<div *ngIf="!formGroupData">
    <div class="title">
        <button mat-icon-button [routerLink]="'..'"><mat-icon>chevron_left</mat-icon></button>
        <h1>Form Group not available</h1>
    </div>

    <mat-card role="region">
        <mat-card-content>
            <p>This form group is not available. Please contact support and include this message.</p>
            <p *ngIf="error">{{errorMessage}}</p>
            <p>Additional support information:</p>
            <code><pre>{{error | json}}</pre></code>
        </mat-card-content>
    </mat-card>
</div>


<div *ngIf="formGroupData">
    <div class="title">
        <button mat-icon-button [routerLink]="'..'"><mat-icon>chevron_left</mat-icon></button>
        <h1>Edit Form Group</h1>
    </div>

    <mat-card role="region">
        <mat-card-content>

            <div>
                <mat-form-field>
                    <mat-label>Form Title</mat-label>
                    <input type="text" matInput [(ngModel)]="formGroupData.title" />
                </mat-form-field>
            </div>

            <div>
                <mat-label>Description</mat-label>
                <ngs-code-editor theme="vs" [codeModel]="codeModelFormGroupDescription" (valueChanged)="formGroupData.description= $event" [options]="codeEditorOptions" />
            </div>

        </mat-card-content>
    </mat-card>

    <mat-card role="region">
        <mat-card-content>
            <div>
                <button mat-raised-button type="button" (click)="save()">Save Form Group</button>
            </div>

            <div *ngIf="saveError">
                <p class="error">Failed to save form group: {{saveErrorMessage}}</p>
                <p>Additional support information:</p>
                <code><pre>{{saveError | json}}</pre></code>
            </div>
            <div *ngIf="saveSuccessful">
                <p class="success">Save Successful</p>
            </div>

        </mat-card-content>
    </mat-card>

</div>
