<div *ngIf="!formData">
    <div class="title">
        <button mat-icon-button [routerLink]="'..'"><mat-icon>chevron_left</mat-icon></button>
        <h1>Form not available</h1>
    </div>

    <mat-card role="region">
        <mat-card-content>
            <p>This form is not available. Please contact support and include this message.</p>
            <p *ngIf="error">{{errorMessage}}</p>
            <p>Additional support information:</p>
            <code><pre>{{error | json}}</pre></code>
        </mat-card-content>
    </mat-card>
</div>


<div *ngIf="formData">
    <div class="title">
        <button mat-icon-button [routerLink]="'..'"><mat-icon>chevron_left</mat-icon></button>
        <h1>Edit Form</h1>
    </div>

    <mat-card role="region">
        <mat-card-content>

            <div>
                <mat-form-field>
                    <mat-label>Form Title</mat-label>
                    <input type="text" matInput required [(ngModel)]="formData.title" maxlength="200" />
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label>Form Group</mat-label>
                <select matNativeControl required [(ngModel)]="formData.group">
                    <option *ngFor="let group of formGroups.groups" [ngValue]="group.id">{{group.title}}
                    </option>
                </select>
            </mat-form-field>

            <div>
                <mat-form-field>
                    <mat-label>Target AzureDevOps Project ID (UUID)</mat-label>
                    <input type="text" matInput required [(ngModel)]="formData.targetProjectID" maxlength="36" />
                </mat-form-field>
            </div>

            <div>
                <mat-form-field>
                    <mat-label>Target AzureDevOps Work Item Type</mat-label>
                    <input type="text" matInput required [(ngModel)]="formData.targetWorkItemType" maxlength="128" />
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label>Target Field for requesting User</mat-label>
                <input type="text" matInput [(ngModel)]="formData.requesterFieldName" maxlength="128" />
            </mat-form-field>

            <div>
                <mat-label>Description</mat-label>
                <ngs-code-editor theme="vs" [codeModel]="codeModelFormDescription"
                    (valueChanged)="formData.description= $event" [options]="codeEditorOptions" />
            </div>

        </mat-card-content>
    </mat-card>

    <mat-card role="region">
        <mat-card-content>
            <h2>Dynamic Fields</h2>
            <p>These fields are presented to the user as inputs.</p>
            <div>
                <div *ngFor="let field of getFields(); index as i">

                    <div>
                        <button mat-button *ngIf="i > 0" (click)="moveUp(i)"><mat-icon>arrow_upward</mat-icon></button>
                        <button mat-button *ngIf="i < getFields().length - 1"
                            (click)="moveDown(i)"><mat-icon>arrow_downward</mat-icon></button>
                        <button mat-button (click)="remove(i)"><mat-icon>delete</mat-icon></button>

                        <mat-form-field>
                            <mat-label>Field Type</mat-label>
                            <select matNativeControl required [(ngModel)]="field.fieldType">
                                <option *ngFor="let fieldtype of getFieldTypes()">{{fieldtype}}
                                </option>
                            </select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Field Title</mat-label>
                            <input type="text" matInput [(ngModel)]="field.title" id="formfield-title-{{i}}" />
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Target Field in Azure DevOps</mat-label>
                            <input type="text" matInput [(ngModel)]="field.azureDevopsFieldname"
                                id="formfield-targetfield-{{i}}" maxlength="128" />
                        </mat-form-field>

                        <mat-checkbox [(ngModel)]="field.mandatory" id="formfield-mandatory-{{i}}" title="Mandatory for checkboxes means the checkbox must be checked,
                        e.g. &quot;I have read and understood the AGB&quot;">
                            Mandatory
                        </mat-checkbox>
                    </div>

                    <div>
                        <mat-label>Description</mat-label>
                        <ngs-code-editor id="code-editor-{{i}}" theme="vs" [codeModel]="codeModelsFieldDescriptions[i]"
                            [options]="codeEditorOptions" (valueChanged)="field.description= $event" />
                    </div>

                    <hr />

                </div>

                <button mat-button (click)="addField()"><mat-icon>add</mat-icon> Add Field</button>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card role="region">
        <mat-card-content>
            <h2>Constant Fields</h2>
            <p>These fields are always added to work items created from this form. They are not displayed to the user.
            </p>
            <div>
                <div *ngFor="let field of getConstantFields(); index as i">

                    <button mat-button *ngIf="i > 0"
                        (click)="moveConstantUp(i)"><mat-icon>arrow_upward</mat-icon></button>
                    <button mat-button *ngIf="i < getConstantFields().length - 1"
                        (click)="moveConstantDown(i)"><mat-icon>arrow_downward</mat-icon></button>
                    <button mat-button (click)="removeConstant(i)"><mat-icon>delete</mat-icon></button>

                    <mat-form-field>
                        <mat-label>Target Field Name</mat-label>
                        <input type="text" matInput [(ngModel)]="field.fieldname" id="constantfield-targetfield-{{i}}"
                            maxlength="128" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Value</mat-label>
                        <input type="text" matInput [(ngModel)]="field.fieldvalue" id="constantfield-value-{{i}}"
                            maxlength="512" />
                    </mat-form-field>

                    <hr />

                </div>

                <button mat-button (click)="addConstantField()"><mat-icon>add</mat-icon> Add Constant Field</button>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card role="region">
        <mat-card-content>
            <div>
                <button mat-raised-button type="button" (click)="save()">Save Form</button>
            </div>

            <div *ngIf="saveError">
                <p class="error">Failed to save form: {{saveErrorMessage}}</p>
                <p>Additional support information:</p>
                <code><pre>{{saveError | json}}</pre></code>
            </div>
            <div *ngIf="saveSuccessful">
                <p class="success">Save Successful</p>
            </div>

        </mat-card-content>
    </mat-card>

</div>
