import { Component, Input, OnInit } from '@angular/core';
import { Form, FormConstantField, FormField, FormGroupList, FormId } from '../api/models';
import { ApiService } from '../api/services';
import { HttpErrorResponse } from '@angular/common/http';
import { CodeModel } from '@ngstack/code-editor';

@Component({
  selector: 'wrplatform-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.css']
})
export class FormEditComponent implements OnInit {

  formGroups: FormGroupList = { groups: [] };
  formData: Form | null = null;
  formID: FormId = 0;
  error: any;
  errorMessage: string = "";

  saveSuccessful: boolean = false;
  saveError: any;
  saveErrorMessage: string = "";

  fieldTypeString = "string";
  fieldTypeMultilineString = "multiline_string";
  fieldTypeDate = "date";
  fieldTypeCheckbox = "checkbox";

  codeModelFormDescription: CodeModel = {
    language: "markdown",
    uri: "Form Description",
    value: "",
  };
  codeModelsFieldDescriptions: CodeModel[] = [];

  // https://microsoft.github.io/monaco-editor/typedoc/interfaces/editor.IEditorConstructionOptions.html
  codeEditorOptions = {
    contextmenu: true,
    minimap: {
      enabled: true,
    },
    scrollbar: {
      // Allow scrolling past editors which are not full.
      // Like this, an editor only consumes scroll events
      // when the content actually exceeds its bounds.
      alwaysConsumeMouseWheel: false,
    },
  };

  constructor(private apiClient: ApiService) { }

  ngOnInit(): void {
    this.refresh();
  }

  @Input()
  set id(formID: FormId) {
    this.formID = formID;
    this.refresh();
  }

  getFields(): FormField[] {
    return this.formData?.fields ?? [];
  }

  getConstantFields(): FormConstantField[] {
    return this.formData?.constantFields ?? [];
  }

  getFieldTypes(): string[] {
    return [
      'string',
      'multiline_string',
      'date',
      'checkbox',
    ];
  }

  save(): void {
    if (this.error || !this.formData) {
      return;
    }

    this.saveError = null;
    this.saveErrorMessage = "";
    this.saveSuccessful = false;

    var responseHandler = {
      next: (formData: Form) => {
        this.saveSuccessful = true;
        this.setFormData(formData);
      },
      error: (error: any) => {
        if (error instanceof HttpErrorResponse) {
          this.saveErrorMessage = error.message;
        } else {
          this.saveErrorMessage = "Unknown Error";
        }
        this.saveError = {
          "error": error,
          "formData": this.formData,
          "formID": this.formID,
          "timestamp": Date(),
        };
      },
    };

    if (this.formID == 0) {
      this.apiClient.createForm({ body: this.formData })
        .subscribe(responseHandler);
    } else {
      this.apiClient.updateForm({ formId: this.formID, body: this.formData })
        .subscribe(responseHandler);
    }
  }

  setFormData(formData: Form): void {
    this.formData = formData;
    this.formID = formData.id;
    this.error = null;
    this.errorMessage = "";

    this.codeModelFormDescription.value = formData.description;
    this.codeModelsFieldDescriptions = [];

    formData.fields.forEach((field, index) => {
      this.codeModelsFieldDescriptions.push({
        language: "markdown",
        uri: "Field Description " + index,
        value: field.description,
      });
    });
  }

  moveUp(index: number): void {
    if (index < 1 || index >= this.getFields().length) {
      return;
    }
    var tmp = this.formData!.fields[index];
    this.formData!.fields[index] = this.formData!.fields[index - 1];
    this.formData!.fields[index - 1] = tmp;

    this.setFormData(this.formData!);
  }

  moveDown(index: number): void {
    if (index < 0 || index >= this.getFields().length - 1) {
      return;
    }
    var tmp = this.formData!.fields[index];
    this.formData!.fields[index] = this.formData!.fields[index + 1];
    this.formData!.fields[index + 1] = tmp;

    this.setFormData(this.formData!);
  }

  remove(index: number): void {
    if (index < 0 || index >= this.getFields().length) {
      return;
    }

    this.formData!.fields.splice(index, 1);

    this.setFormData(this.formData!);
  }

  addField(): void {
    if (!this.formData) {
      return;
    }

    this.formData.fields.push({
      description: '',
      fieldType: 'string',
      mandatory: false,
      title: '',
      azureDevopsFieldname: '',
    });

    this.setFormData(this.formData!);
  }

  moveConstantUp(index: number): void {
    if (index < 1 || index >= this.getConstantFields().length) {
      return;
    }
    var tmp = this.formData!.constantFields[index];
    this.formData!.constantFields[index] = this.formData!.constantFields[index - 1];
    this.formData!.constantFields[index - 1] = tmp;

    this.setFormData(this.formData!);
  }

  moveConstantDown(index: number): void {
    if (index < 0 || index >= this.getConstantFields().length - 1) {
      return;
    }
    var tmp = this.formData!.constantFields[index];
    this.formData!.constantFields[index] = this.formData!.constantFields[index + 1];
    this.formData!.constantFields[index + 1] = tmp;

    this.setFormData(this.formData!);
  }

  removeConstant(index: number): void {
    if (index < 0 || index >= this.getConstantFields().length) {
      return;
    }

    this.formData!.constantFields.splice(index, 1);

    this.setFormData(this.formData!);
  }

  addConstantField(): void {
    if (!this.formData) {
      return;
    }

    this.formData.constantFields.push({
      fieldname: '',
      fieldvalue: '',
    });

    this.setFormData(this.formData!);
  }


  refresh(): void {
    // FIXME: This call can get a race condition with getForm below
    this.apiClient.getFormGroups()
      .subscribe({
        next: (formGroups) => {
          this.formGroups = formGroups;

          // Ensure a form group is selected, otherwise the select may show a value but the internal state may not have one
          if (this.formData && this.formData.group == 0 && this.formGroups.groups.length > 0) {
            this.formData.group = this.formGroups.groups[0].id;
          }
        },
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = "Unknown Error";
          }
          this.formData = null;
          this.error = {
            "error": error,
            "formData": null,
            "formID": this.formID,
            "timestamp": Date(),
          };
        },
      });

    if (this.formID == 0) {
      this.setFormData({
        description: '',
        fields: [],
        group: 0,
        id: 0,
        title: '',
        constantFields: [],
        enabled: false,
        visible: false,
        targetProjectID: '',
        targetWorkItemType: '',
        requesterFieldName: '',
      });

      // Ensure a form group is selected, otherwise the select may show a value but the internal state may not have one
      if (this.formData && this.formData.group == 0 && this.formGroups.groups.length > 0) {
        this.formData.group = this.formGroups.groups[0].id;
      }
      return;
    }
    this.apiClient.getForm({ formId: this.formID })
      .subscribe({
        next: (formData) => {
          this.setFormData(formData);

          // Ensure a form group is selected, otherwise the select may show a value but the internal state may not have one
          if (this.formData && this.formData.group == 0 && this.formGroups.groups.length > 0) {
            this.formData.group = this.formGroups.groups[0].id;
          }
        },
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = "Unknown Error";
          }
          this.formData = null;
          this.error = {
            "error": error,
            "formData": null,
            "formID": this.formID,
            "timestamp": Date(),
          };
        },
      });
  }

}
