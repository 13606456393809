import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { CodeModel } from '@ngstack/code-editor';
import { FormGroup, FormGroupId } from '../api/models';
import { ApiService } from '../api/services';

@Component({
  selector: 'wrplatform-formgroup-edit',
  templateUrl: './formgroup-edit.component.html',
  styleUrls: ['./formgroup-edit.component.css']
})
export class FormgroupEditComponent {
  formGroupData: FormGroup | null = null;
  formGroupID: FormGroupId = 0;
  error: any;
  errorMessage: string = "";

  saveSuccessful: boolean = false;
  saveError: any;
  saveErrorMessage: string = "";

  codeModelFormGroupDescription: CodeModel = {
    language: "markdown",
    uri: "Form Group Description",
    value: "",
  };

  codeEditorOptions = {
    contextmenu: true,
    minimap: {
      enabled: true,
    },
    scrollbar: {
      // Allow scrolling past editors which are not full.
      // Like this, an editor only consumes scroll events
      // when the content actually exceeds its bounds.
      alwaysConsumeMouseWheel: false,
    },
  };

  constructor(private apiClient: ApiService) { }

  ngOnInit(): void {
    this.refresh();
  }

  @Input()
  set id(formGroupID: FormGroupId) {
    this.formGroupID = formGroupID;
    this.refresh();
  }


  save(): void {
    if (this.error || !this.formGroupData) {
      return;
    }

    this.saveError = null;
    this.saveErrorMessage = "";
    this.saveSuccessful = false;

    var responseHandler = {
      next: (formGroupData: FormGroup) => {
        this.saveSuccessful = true;
        this.setFormGroupData(formGroupData);
      },
      error: (error: any) => {
        if (error instanceof HttpErrorResponse) {
          this.saveErrorMessage = error.message;
        } else {
          this.saveErrorMessage = "Unknown Error";
        }
        this.saveError = {
          "error": error,
          "formGroupData": this.formGroupData,
          "formGroupID": this.formGroupID,
          "timestamp": Date(),
        };
      },
    };

    if (this.formGroupID == 0) {
      this.apiClient.createFormGroup({ body: this.formGroupData })
        .subscribe(responseHandler);
    } else {
      this.apiClient.updateFormGroup({ formGroupId: this.formGroupID, body: this.formGroupData })
        .subscribe(responseHandler);
    }
  }

  setFormGroupData(formGroupData: FormGroup): void {
    this.formGroupData = formGroupData;
    this.formGroupID = formGroupData.id;
    this.error = null;
    this.errorMessage = "";

    this.codeModelFormGroupDescription.value = formGroupData.description;
  }


  refresh(): void {
    if (this.formGroupID == 0) {
      this.setFormGroupData({
        description: '',
        id: 0,
        title: ''
      });
      return;
    }
    this.apiClient.getFormGroup({ formGroupId: this.formGroupID })
      .subscribe({
        next: (formGroupData) => {
          this.setFormGroupData(formGroupData);
        },
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = "Unknown Error";
          }
          this.formGroupData = null;
          this.error = {
            "error": error,
            "formGroupData": this.formGroupData,
            "formGroupID": this.formGroupID,
            "timestamp": Date(),
          };
        },
      });
  }
}
